<script>
export default {
    props: {
        amount: {},
        currency: {}
    },
    computed: {
        calculatedClasses() {
            if (this.amount == 0) {
                return 'text-muted';
            }
        }
    }
};
</script>

<template>
    <span :class="calculatedClasses">
        <template v-if="amount != 0">
            {{
                $n(amount, {
                    style: "currency",
                    currency: currency,
                    currencyDisplay: "code"
                })
            }}
        </template>
        <template v-else>---</template>
    </span>
</template>
