<script>
import DaylogService from "@/services/DaylogService";
import VTripleTime from "@/components/Task/Widgets/VTripleTime";
import LeClock from "../Clockin/LeClock";
import HalfDashCard from "@/components/Cards/HalfDashCard";
import Cloner from "@/libraries/Cloner";
import Messages from "@/mixins/Messages";
import dayjs from "dayjs";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        date: {}
    },
    mixins: [Messages],
    components: { VTripleTime, LeClock, HalfDashCard },
    data() {
        return {
            editing: false,
            saving: false,
            loading: true,
            pickDate: "",
            day: {
                id: null,
                entry_date: null,
                time_start: null,
                time_stop: null,
                total_time: null,
                pause_time: null,
                work_time: null,
                status: null
            },
            errors: {}
        };
    },
    computed: {
        time_start() {
            return this.day.time_start;
        },
        time_stop() {
            return this.day.time_stop;
        },
        pause_time() {
            return this.day.pause_time;
        }
    },
    watch: {
        time_start: function(v) {
            this.timerWatch(v);
        },
        time_stop: function(v) {
            this.timerWatch(v);
        },
        pause_time: function(v) {
            this.timerWatch(v);
        }
    },
    async created() {
        if (this.date === null || typeof this.date === "undefined") {
            this.pickDate = dayjs().format("YYYY-MM-DD");
        } else {
            this.pickDate = this.date;
        }
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await DaylogService.GET_currentDay(
                    this.pickDate
                );
                this.day = Cloner.pickClone(data.data, [
                    "id",
                    "entry_date",
                    "time_start",
                    "time_stop",
                    "total_time",
                    "pause_time",
                    "work_time",
                    "status"
                ]);
            } catch (e) {
                console.log("fd, e", e);
            }
        },
        async saveForm() {
            try {
                if (this.day.status === 0) {
                    const { data } = await DaylogService.POST(this.day);
                } else {
                    const { data } = await DaylogService.PUT(this.day);
                }
                this.errors = {};
                this.$msgSuccess(
                    this.$t("daylogs.notify_registration_data_saved")
                );
                EventBus.$emit("daylogs-change");
                this.$emit("close");
            } catch (e) {
                this.errors = e.response.data.errors;
                console.log("sf, e", e);
                this.$msgError(this.$t("daylogs.notify_error_sth_went_wrong"));
            }
        },
        timerWatch(nV) {
            if (
                typeof this.day.time_start === "string" &&
                typeof this.day.time_stop === "string"
            ) {
                const from = this.day.time_start.split(":");
                const to = this.day.time_stop.split(":");

                let from_hr = parseInt(from[0]) * 60 + parseInt(from[1]);
                let to_hr = parseInt(to[0]) * 60 + parseInt(to[1]);

                let day_diff = (to_hr - from_hr) * 60;
                const pause = parseInt(this.day.pause_time);

                // Adjust the pause NPI
                if (pause == 0) {
                    if (day_diff > 21600 && day_diff <= 32400) {
                        this.day.pause_time = 30 * 60;
                    } else if (day_diff > 32400) {
                        this.day.pause_time = 45 * 60;
                    }
                } else {
                    if (
                        day_diff > 21600 &&
                        day_diff <= 32400 &&
                        pause < 30 * 60
                    ) {
                        this.day.pause_time = 30 * 60;
                    } else if (day_diff > 32400 && pause < 45 * 60) {
                        this.day.pause_time = 45 * 60;
                    }
                }

                let day_p_diff = (to_hr - from_hr) * 60 - pause;

                if (day_p_diff < 0) {
                    day_p_diff = 0;
                }
                console.log(day_p_diff, day_diff);

                this.day.total_time = day_diff;
                this.day.work_time = day_p_diff;
            }
        },
        navigateOverview() {
            this.$emit("close");
            this.$router.push({ name: "DaylogsMyIndex" });
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal daylog-form"
        size="xl"
    >
        <template slot="header">
            {{
                $t("daylogs.date_DATE", {
                    DATE: $d(new Date(day.entry_date), "short")
                })
            }}
        </template>

        <template slot="default">
            <v-loader v-if="loading" :loading="loading" />
            <template v-else>
                <div class="row">
                    <div class="col-4">
                        <HalfDashCard
                            :title="$t('daylogs.statbox_total_time')"
                            disable-hover
                            icon="far fa-sigma"
                            >{{ day.total_time | toTime }}</HalfDashCard
                        >
                    </div>
                    <div class="col-4">
                        <HalfDashCard
                            :title="$t('daylogs.statbox_pause_time')"
                            disable-hover
                            icon="far fa-pause-circle"
                            >{{ day.pause_time | toTime }}</HalfDashCard
                        >
                    </div>
                    <div class="col-4">
                        <HalfDashCard
                            :title="$t('daylogs.statbox_work_time')"
                            disable-hover
                            icon="far fa-cog fa-spin"
                            >{{ day.work_time | toTime }}</HalfDashCard
                        >
                    </div>
                </div>
                <hr class="mt-0 mb-5" />

                <div class="row">
                    <div class="col-4 text-center">
                        <le-clock v-model="day.time_start" />
                        <div class="label">{{ $t("daylogs.start_time") }}</div>
                        <div class="small text-muted">
                            {{ $t("daylogs.time_of_day") }}
                        </div>
                        <v-form-error name="time_start" :errors="errors" />
                    </div>
                    <div class="col-4 ">
                        <v-triple-time
                            style=" width: 210px; margin-left: auto; margin-right: auto;"
                            v-model="day.pause_time"
                            :enable-controls="true"
                            :interval="300"
                        />
                        <div class="label">
                            {{ $t("daylogs.pause_length") }}
                        </div>
                        <div class="text-center small text-muted">
                            {{ $t("daylogs.duration_in_min") }}
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <le-clock v-model="day.time_stop" />
                        <div class="label">{{ $t("daylogs.stop_time") }}</div>
                        <div class="small text-muted">
                            {{ $t("daylogs.time_of_day") }}
                        </div>
                        <v-form-error name="time_stop" :errors="errors" />
                    </div>
                </div>

                <div class="status">
                    <div v-if="day.status === 0">
                        <i class="far fa-empty-set"></i>
                        {{ $t("daylogs.status") }}:
                        {{ $t("daylogs.status_empty") }}
                    </div>
                    <div v-else-if="day.status === 1">
                        <i class="far fa-lock-open"></i>
                        {{ $t("daylogs.status") }}:
                        {{ $t("daylogs.status_open") }}
                    </div>
                    <div v-else-if="day.status === 2">
                        <i class="far fa-lock"></i> {{ $t("daylogs.status") }}:
                        {{ $t("daylogs.status_closed") }}
                    </div>
                </div>

                <hr class="mt-4" />

                <div class="d-flex text-right mt-5">
                    <div class="">
                        <base-button
                            size="lg"
                            type="default"
                            @click="navigateOverview"
                            outline
                            ><i class="far fa-calendar-alt"></i>
                            {{ $t("daylogs.go_to_overview") }}</base-button
                        >
                    </div>
                    <div class="ml-auto">
                        <base-button
                            size="lg"
                            type="white"
                            @click="$emit('close')"
                            >{{ $t("daylogs.cancel") }}</base-button
                        >
                        <base-button
                            size="lg"
                            type="primary"
                            @click="saveForm"
                            :loading="saving"
                            >{{ $t("daylogs.save") }}</base-button
                        >
                    </div>
                </div>
                <pre v-if="false">{{ day }}</pre>
            </template>
        </template>
    </modal>
</template>

<style lang="scss">
.daylog-form {
    .label {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
    }

    .status {
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        color: $info;
    }
}
</style>
