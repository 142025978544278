<script>
export default {
    props: {
        amount: {},
        currency: {},
        budget: {
            type: Number,
            default: 0
        },
        used: {
            type: Number,
            default: 0
        }
    },
    computed: {
        calculatedClasses() {
            if (this.amount == 0) {
                return "text-muted";
            }

            return this.budgetColor();
        }
    },
    methods: {
        budgetColor() {
            if (this.amount != 0) {
                const perc = (this.used / this.budget) * 100;
                
                if (perc < 80) {
                    return 'text-primary';
                } else if (perc >= 80 && perc <=100) {
                    return 'text-warning';
                } else if (perc > 100) {
                    return 'text-danger';
                }

            }
        }
    }
};
</script>

<template>
    <span :class="calculatedClasses">{{
        $n(amount, {
            style: "currency",
            currency: currency,
            currencyDisplay: "code"
        })
    }}</span>
</template>
