import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";
import ProjectService from "@/services/ProjectService";

/* PROJECTS (CASES) */
const projectsMenu = {
    path: "/projects",
    name: "Projects",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.cases")
    },
    children: [
        {
            path: "display/:project_id",
            name: "ProjectDisplay",
            beforeEnter: async (to, from, next) => {
                const projectId = to.params.project_id;
                console.log("Project guard", projectId);

                // Fetch project data based on ID (simulate API call or local fetch)
                try {
                    const { data } = await ProjectService.GET_projectInterface(
                        projectId
                    );
                    const project = data.data;
                    // Redirect based on the project type
                    if (project.project_interface === 1) {
                        next(`/projects/view/${projectId}/tasks`);
                    } else if (project.project_interface === 2) {
                        // next(`/projects/planning/${projectId}/intro`);
                        next(`/projects/planning/${projectId}/milestones`);
                    } else {
                        next(`/projects/view/${projectId}/tasks`);
                    }
                } catch (e) {
                    console.log("WHAT THE DUF", e);
                    next("/404"); // Redirect to a 404 page if the project type is invalid
                }
            }
        },
        {
            path: "index",
            name: "ProjectsIndex",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VIndex"
                ),
            meta: {
                title: i18n.t("title.all_cases")
            }
        },
        {
            path: "reopen",
            name: "ProjectsReopenDay",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VReopen"
                ),
            meta: {
                title: i18n.t("title.reopen_day")
            }
        },
        {
            path: "my-overview",
            name: "ProjectsMyCases",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VMyCases"
                ),
            meta: {
                title: i18n.t("title.my_cases_overview")
            }
        },
        {
            path: "my-invoices",
            name: "ProjectsInvoicingReportResponsible",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VInvoicesResponsible"
                ),
            meta: {
                title: i18n.t("title.my_cases_overview")
            }
        },
        {
            path: "full-overview",
            name: "ProjectsFullCases",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VAllCases"
                ),
            meta: {
                title: i18n.t("title.all_cases_overview")
            }
        },
        {
            path: "invoicing-report",
            name: "ProjectsInvoicingReport",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VInvoices"
                ),
            meta: {
                title: i18n.t("title.projects_invoicing_report")
            }
        },
        {
            // Common routes and classical projects
            path: "view/:project_id",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VProjectView"
                ),
            props: true,
            children: [
                {
                    path: "stats",
                    name: "ProjectViewStats",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VStats"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "contract-stats",
                    name: "ProjectViewContractStats",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VContractStats"
                            )
                    },
                    meta: {
                        title: i18n.t("title.contract_statistics")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "tasks",
                    name: "ProjectViewTasks",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VTasks"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "task-manager",
                    name: "ProjectViewPricer",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VPricer"
                            )
                    },
                    meta: {
                        title: i18n.t("title.task_manager")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "expenses",
                    name: "ProjectViewExpenses",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VExpenses"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "transport",
                    name: "ProjectViewTransport",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VTransport"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "invoicing",
                    name: "ProjectViewInvoicing",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VInvoicing"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "invoice/:invoice_id",
                    name: "ProjectViewInvoice",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VInvoice"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "settings",
                    name: "ProjectViewSettings",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VSettings"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "milestones",
                    name: "ProjectViewMilestones",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VMilestones"
                            )
                    },
                    meta: {
                        title: i18n.t("title.milestones")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "costs",
                    name: "ProjectViewCosts",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VCosts"
                            )
                    },
                    meta: {
                        title: i18n.t("title.costs")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "costs-inbox",
                    name: "ProjectViewCostsInbox",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VCostsInbox"
                            )
                    },
                    meta: {
                        title: i18n.t("title.costs_inbox")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "costs-report",
                    name: "ProjectViewCostsReport",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VCostsReport"
                            )
                    },
                    meta: {
                        title: i18n.t("title.costs_inbox")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "costs-budget-report",
                    name: "ProjectViewCostsBudgetReport",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VCostsBudgetReport"
                            )
                    },
                    meta: {
                        title: i18n.t("title.costs_inbox")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "costs-detailed-budget-report",
                    name: "ProjectViewCostsDetailedBudgetReport",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VCostsDetailedBudgetReport"
                            )
                    },
                    meta: {
                        title: i18n.t("title.costs_inbox")
                    },
                    props: {
                        project_tab: true
                    }
                }
            ]
        },
        // Planning routes
        {
            path: "portfolio",
            name: "ProjectPlanningPortfolio",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/ProjectsPlanning/VPortfolio"
                ),
            meta: {
                title: i18n.t("title.portfolio"),
                hideTop: true
            }
        },
        {
            path: "planning-report",
            name: "ProjectPlanningReport",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/ProjectsPlanning/VPlanningReport"
                ),
            meta: {
                title: i18n.t("title.project_report"),
                hideTop: true
            }
        },

        {
            path: "planning/:project_id",
            name: "ProjectPlanningView",
            component: () =>
                import(
                    /* webpackChunkName: "planning" */ "@/views/ProjectsPlanning/VPlanningView"
                ),
            meta: {
                title: i18n.t("title.cases"),                
            },
            props: true,
            children: [
                {
                    path: "intro",
                    name: "ProjectPlanningStart",
                    components: {
                        planning_tab: () =>
                            import(
                                /* webpackChunkName: "planning" */ "@/views/ProjectsPlanning/Tabs/VStart"
                            )
                    },
                    meta: {
                        title: i18n.t("title.planning_start"),
                        hideTop: true
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "milestones",
                    name: "ProjectPlanningMilestones",
                    components: {
                        planning_tab: () =>
                            import(
                                /* webpackChunkName: "planning" */ "@/views/ProjectsPlanning/Tabs/VMilestones"
                            )
                    },
                    meta: {
                        title: i18n.t("title.planning_milestones"),
                        hideTop: true
                    },
                    props: {
                        planning_tab: true
                    }
                },
                {
                    path: "project-data",
                    name: "ProjectPlanningDataTab",
                    components: {
                        planning_tab: () =>
                            import(
                                /* webpackChunkName: "planning" */ "@/views/ProjectsPlanning/Tabs/VDataTab"
                            )
                    },
                    meta: {
                        title: i18n.t("title.data_tab"),
                        hideTop: true
                    },
                    props: {
                        planning_tab: true
                    }
                },
                {
                    path: "links",
                    name: "ProjectPlanningLinks",
                    components: {
                        planning_tab: () =>
                            import(
                                /* webpackChunkName: "planning" */ "@/views/ProjectsPlanning/Tabs/VLinks"
                            )
                    },
                    meta: {
                        title: i18n.t("title.links"),
                        hideTop: true
                    },
                    props: {
                        planning_tab: true
                    }
                }
            ]
        }
    ]
};

export default projectsMenu;
