<script>
export default {
    props: {
        name: {},
        errors: {}
    },
    computed: {
        errorString() {
            if (this.name in this.errors) {
                return this.errors[this.name];
            }
        }
    }
};
</script>

<template>
    <div>
        <template v-if="name in errors">
            <div class="alert text-danger">
                <div v-for="(err, idx) in errorString">
                    {{ err }}
                </div>
            </div>
        </template>
    </div>
</template>
