import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const daylogsMenu = {
    path: "/daylogs",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.daylogs")
    },
    children: [
        {
            path: "",
            name: "DaylogsMyIndex",
            component: () => import("@/views/Daylogs/VIndex"),
            meta: {
                title: i18n.t("title.my_daylogs_registration")
            }
        }
    ]
};

export default daylogsMenu;
