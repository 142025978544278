var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',[_c('menu-widget',{attrs:{"select":"projects"}}),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.registration'),
                icon: 'far fa-cabinet-filing'
            },"force-navigation":{ name: 'StartDashboard' }}},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_cases_overview'),
                    path: '/projects/my-overview'
                }}}),(true)?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_tasks'),
                    icon: 'far fa-tasks',
                    path: '/tasks/my-tasks'
                }}}):_vm._e(),(true)?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_travels'),
                    icon: 'far fa-cars',
                    path: '/travels/my-travels'
                }}}):_vm._e(),(true)?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_hours'),
                    icon: 'far fa-hourglass',
                    path: '/reports/my-hours'
                }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.development'),
                icon: 'far fa-calendar-alt'
            }}},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.planning_portfolio'),
                    path: '/projects/portfolio'
                }}}),(false)?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.projects_my_projects'),
                    path: '/projects/portfolio?preset=mine'
                }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.planning_report'),
                    path: '/projects/planning-report'
                }}}),(_vm.$aclCan(_vm.$ACL.CUSTOM_PLANNING_EXP_DEVMODEL))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.data_exports'),
                    path: '/reports/projects-reports'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.global_inbox'),
                    path: '/vouchers/inbox'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.global_booked_costs'),
                    path: '/vouchers/index'
                }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.cases'),
                icon: 'far fa-clipboard-list-check'
            }}},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.all_cases'),
                    path: '/projects/index'
                }}}),(_vm.$aclCan(_vm.$ACL.CUSTOM_PLANNING_CREATE_PROJ))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.create_case'),
                    path: '/projects/index?create=case'
                }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_cases_overview'),
                    path: '/projects/my-overview'
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.my_cases_invoices'),
                    path: '/projects/my-invoices'
                }}}),(_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.reopen_day'),
                    icon: 'far fa-tasks',
                    path: '/projects/reopen'
                }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.mfas'),
                icon: 'far fa-badge-check'
            }}},[(_vm.$aclCan(_vm.$ACL.CUSTOM_PLANNING_MFA))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.mfa_flags'),
                    path: '/project-flags/index'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.CUSTOM_PLANNING_MFA))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.mfa_status'),
                    path: '/project-flags/status-mfa'
                }}}):_vm._e()],1),(_vm.getIsAdmin)?_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.administrative'),
                icon: 'far fa-users',
                path: '/users/index'
            },"hide-mobile":true}},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.all_customers'),
                    path: '/customers/index'
                }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('menu.users'), path: '/users/index' },"hide-mobile":true}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.departments'),
                    path: '/departments/index'
                },"hide-mobile":true}})],1):_vm._e(),(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('menu.bookkeepers'), icon: 'far fa-book' },"hide-mobile":true}},[(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.travel_viewer'),
                    icon: 'far fa-tasks',
                    path: '/travels/index'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.travel_salary'),
                    icon: 'far fa-tasks',
                    path: '/travels/salary'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.INVOICE_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.travel_salary_reports'),
                    icon: 'far fa-tasks',
                    path: '/travels/salary-reports'
                }}}):_vm._e()],1):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('menu.reports'), icon: 'far fa-chart-pie' },"hide-mobile":true}},[(_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.all_tasks'),
                    icon: 'far fa-tasks',
                    path: '/tasks/index'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.all_cases_overview'),
                    icon: 'far fa-tasks',
                    path: '/projects/full-overview'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.projects_invoicing_report'),
                    icon: 'far fa-tasks',
                    path: '/projects/invoicing-report'
                }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.hour_report'),
                    path: '/reports/activities'
                }}}),(_vm.$aclCan(_vm.$ACL.PERSONAL_REPORT_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.absence'),
                    path: '/reports/absence'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.cases'),
                    path: '/reports/projects'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.cases_performance'),
                    path: '/reports/projects-performance'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.open_days'),
                    path: '/reports/open-days'
                }}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.contract_reports'),
                    path: '/reports/contracts'
                },"hide-mobile":true}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.PERSONAL_REPORT_MGMT))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.personal_multiview'),
                    path: '/reports/personal-multiview'
                },"hide-mobile":true}}):_vm._e(),(_vm.$aclCan(_vm.$ACL.STATS_VIEW))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('menu.budget'), path: '/reports/budget' },"hide-mobile":true}}):_vm._e()],1):_vm._e(),(_vm.$aclCan(_vm.$ACL.TIMEREG_MGMT))?_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.settings'),
                icon: 'far fa-cogs',
                path: '/settings'
            }}},[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.activities'),
                    path: '/settings/activities'
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.milestones'),
                    path: '/settings/milestones'
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.role_prices'),
                    path: '/settings/role-prices'
                }}}),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('menu.travel_prices'),
                    path: '/travel-prices'
                }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
                name: _vm.$t('menu.help'),
                icon: 'far fa-question-circle',
                path: '/help'
            }}}),_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.doLogout.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-sign-out"}),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.$t("menu.logout")))])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }