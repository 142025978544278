import NotFound from "@/views/GeneralViews/NotFoundPage.vue";
import startMenu from "./routes/startMenu";
import dashboardMenu from "./routes/dashboardMenu";
import tasksMenu from "./routes/tasksMenu";
import projectsMenu from "./routes/projectsMenu";
import contractsMenu from "./routes/contractsMenu";
import customersMenu from "./routes/customersMenu";
import invoicingMenu from "./routes/invoicingMenu";
import usersMenu from "./routes/usersMenu";
import subscriptionsMenu from "./routes/subscriptionsMenu";
import departmentsMenu from "./routes/departmentsMenu";
import loginMenu from "./routes/loginMenu";
import reportsMenu from "./routes/reportsMenu";
import profileMenu from "./routes/profileMenu";
import settingsMenu from "./routes/settingsMenu";
import travelPricesMenu from "./routes/travelPricesMenu";
import travelsMenu from "./routes/travelsMenu";
import helpMenu from "./routes/helpMenu";
import projectFlagsMenu from "./routes/projectFlagsMenu";
import vouchersMenu from "./routes/vouchersMenu";
import daylogsMenu from "./routes/daylogsMenu";
import oauth2Menu from "./routes/oauth2Menu";
import errorsMenu from "./routes/errorsMenu";

const routes = [
    startMenu,
    dashboardMenu,
    loginMenu,
    invoicingMenu,
    contractsMenu,
    customersMenu,
    usersMenu,
    reportsMenu,
    settingsMenu,
    profileMenu,
    helpMenu,
    tasksMenu,
    projectsMenu,
    departmentsMenu,
    travelsMenu,
    travelPricesMenu,
    subscriptionsMenu,
    projectFlagsMenu,
    vouchersMenu,
    daylogsMenu,
    oauth2Menu,
    errorsMenu,
    {
        path: "*",
        component: NotFound
    }
];

export default routes;
