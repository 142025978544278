import MiscTools from "@/mixins/MiscTools";
import Acl from "@/mixins/Acl";
import TenantMixin from "./TenantMixin";

export default {
    install(Vue) {
        Vue.mixin(MiscTools);
        Vue.mixin(Acl);        
        Vue.mixin(TenantMixin);
    }
};
