<script>
import dayjs from "dayjs";

export default {
    props: {
        value: {},
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        t_hours: {
            get() {
                const clock = this.splitTime(this.value);
                return clock.hours;
            },
            set(value) {
                this.$emit("input", this.constructTime(value, this.t_mins));
            }
        },
        t_mins: {
            get() {
                const clock = this.splitTime(this.value);
                return clock.minutes;
            },
            set(value) {
                this.$emit("input", this.constructTime(this.t_hours, value));
            }
        }
    },
    methods: {
        splitTime(timeString) {
            if (!timeString) return { hours: "00", minutes: "00" };

            const [hours, minutes] = timeString.split(":");

            return {
                hours: String(hours).padStart(2, "0"),
                minutes: String(minutes).padStart(2, "0")
            };
        },
        constructTime(hours, minutes) {
            // Ensure valid numbers and pad with zeroes
            let h = parseInt(hours, 10);
            let m = parseInt(minutes, 10);

            if (isNaN(h) || h < 0) h = 0;
            if (h > 23) h = 23; // Cap at 23
            if (isNaN(m) || m < 0) m = 0;
            if (m > 59) m = 59; // Cap at 59

            return `${String(h).padStart(2, "0")}:${String(m).padStart(
                2,
                "0"
            )}:00`;
        },
        modifyTime(sign) {
            const change_mins = 5;

            const [hours, minutes] =
                this.value === null
                    ? dayjs()
                          .subtract(5, "minute")
                          .format("HH:mm")
                          .split(":")
                    : this.value.split(":");

            let hr = parseInt(hours) * 60 + parseInt(minutes);
            hr = parseInt(hr) + parseInt(sign) * parseInt(change_mins);
            this.$emit("input", this.constructTime(parseInt(hr / 60), hr % 60));
        }
    }
};
</script>

<template>
    <div class="le-clock">
        <span class="le-clock__pm le-clock__minus" @click="modifyTime(-1)"
            ><i class="fas fa-minus fa-lg fa-fw"></i
        ></span>
        <input
            :disabled="disabled"
            type="text"
            class="le-clock__tinput le-clock__hours"
            v-model.lazy="t_hours"
        /><span class="le-clock__sep">:</span
        ><input
            :disabled="disabled"
            type="text"
            class="le-clock__tinput le-clock__minutes"
            v-model.lazy="t_mins"
        />
        <span class="le-clock__pm le-clock__plus" @click="modifyTime(1)"
            ><i class="fas fa-plus fa-lg fa-fw"></i
        ></span>
    </div>
</template>

<style lang="scss">
.le-clock {
    &__pm {
        line-height: 2rem;
        padding: 5px 5px 4px;
        font-size: 24px;
        border: 1px solid white;

        i {
            color: $gray-500;
            font-weight: 400;
            font-size: 24px;
        }

        &:hover {
            background: $gray-100;
            border: 1px solid $gray-100;
            border-radius: 6px;
            cursor: pointer;
            i {
                color: $primary;
            }
        }
    }

    &__minus {
        margin-right: 5px;
    }

    &__plus {
        margin-left: 5px;
    }

    &__sep {
        font-size: 28px;
    }
    &__tinput {
        border: 1px solid $gray-300;
        border-radius: 6px;
        width: 60px;
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: rgb(49, 54, 54);
        line-height: 38px;
    }
}
</style>
