/**
 * Setup for global critical data:
 * $CURRENCY = default currency
 * $TENANT = dk or de tenant code
 */
export default {
    computed: {
        $currency() {
            return this.$CURRENCY;
        },
        $tenant() {
            return this.$TENANT;
        },
        $isDE() {
            if (this.$TENANT === "de") {
                return true;
            } else {
                return false;
            }
        },
        $isDK() {
            if (this.$TENANT === "dk") {
                return true;
            } else {
                return false;
            }
        }
    }
};
